.ocpp-console-wrap {
  padding: 0 10px;
  height: 100%;
  display: grid;
  grid-template-rows: 24px calc(100% - 24px);
  gap: 5px;

  .ocpp-console-message {
    padding: 5px;
    width: 100%;
    height: 95%;
    border: 1px solid var(--grey-1);
    border-radius: 5px;

    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    gap: 5px;

    .single-message-wrap {
      display: flex;
      gap: 5px;

      h3 {
        font-size: 14px;
        font-weight: 700;
      }

      p {
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
}
