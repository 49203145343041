.ev-layout-wrap {
  width: 100vw;
  height: 100vh;

  display: grid;
  grid-template-columns: 120px calc(100vw - 120px);

  .ev-sidebar {
    width: 100%;
    height: 100%;
  }

  .ev-content-wrap {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: 100px calc(100vh - 100px);

    overflow-y: scroll;

    overflow-x: hidden;
    .ev-header {
    }

    .ev-content {
      width: 100%;
      height: 100%;
      padding: 0 5px;
    }
  }

  .ocpp-message-content-wrap {
    width: 100%;
    height: 100vh;

    overflow-y: scroll;

    overflow-x: hidden;

    .ocpp-message-conetnt {
      padding: 10px 20px;

      width: 100%;
      height: 70vh;

      overflow-y: scroll;
      overflow-x: hidden;
    }

    .ocpp-console {
      width: 100%;
      height: 30vh;
    }
  }
}

@media screen and (max-width: 480px) {
  .ev-layout-wrap {
    grid-template-columns: 100%;
    .ev-sidebar {
      display: none;
    }
    .ev-content-wrap {
      .admin-header-warp {
        .admin-header-left {
          display: flex;
        }
        .admin-header-content {
          .admin-header-center {
            .header-image {
              img {
                width: calc(100vw - 200px);
              }
            }
          }
          .admin-header-right {
            .item {
              .profile {
                .profile-details {
                  .profile-name {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
      .ev-content {
        .container {
          padding: 0px 0px 0px 10px;
          .body-container {
            .table-content {
              .table-content {
                .card-details {
                  .card-list {
                    .card-box-warp {
                      width: 350px;
                    }
                  }
                  .pagination {
                    align-items: start;
                    flex-direction: column;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .pagination-wrap {
    flex-direction: column;
    gap: 10px;
  }
}
