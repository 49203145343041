.multi-select-dropdown {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .multi-select-header {
    color: var(--secondary-color);
  }

  label {
    font-size: 14px;
    margin-bottom: 4px;
    color: var(--text-color);
    display: block;
  }

  .multi-select-dropdown-list {
    position: relative;

    .dropdown-header {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 12px;
      border: 1px solid var(--border-grey);
      border-radius: 12px;
      background-color: rgba(var(--grey-bg), 0.5);
      cursor: pointer;

      .left-section {
        display: flex;
        align-items: center;
        gap: 6px;
        height: 24px;

        span {
          font-size: 16px;
          color: var(--grey-1);
        }
      }

      .dropdown-arrow {
        font-size: 18px;
        transition: transform 0.3s ease;
        color: var(--secondary-color);
        margin-top: 5px;
        &.open {
          transform: rotate(180deg);
        }
      }
    }

    .dropdown-content {
      top: 87%;
      width: 100%;
      position: absolute;
      z-index: 999;
      border: 1px solid var(--multi-select-border);
      border-radius: 0 0 8px 8px;
      max-height: 0;
      opacity: 0;
      transform: translateY(-80%);
      overflow-y: hidden;
      display: flex;
      flex-direction: column;
      // gap: 20px;
      transition:
        transform 1s ease,
        max-height 0.3s ease-out,
        opacity 0.3s ease;
      background-color: var(--dropdown-background-bg);
      backdrop-filter: blur(10px);

      .multi-select-search {
        margin: 10px 12px 0 12px;
        display: grid;
        grid-template-columns: 90% 10%;
        justify-content: space-between;
        border-bottom: 1px solid #e3e8f1;

        input {
          background-color: transparent;
          border: none;
          outline: none;
          font-size: 16px;
          color: var(--secondary-color);
        }

        .search-box {
          color: var(--secondary-color);
        }
      }
    }

    &.open .dropdown-content {
      max-height: 200px;
      opacity: 1;
      transform: translateY(0);
      overflow-y: hidden;

      .dropdown-list {
        overflow-y: scroll;
        max-height: 200px;

        &::-webkit-scrollbar {
          display: none;
        }

        .dropdown-lists {
          color: var(--secondary-color);
        }

        .slide-left {
          width: 100%;
          display: flex;
          align-items: center;
          gap: 6px;
          opacity: 0;
          transform: translateX(-20px);
          animation: slideInLeft 1s forwards;
          transition-delay: 0.1s;

          p {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px;

            &:hover {
              border-radius: 8px;
              background-color: var(--white-color);
            }
          }
        }

        padding: 12px;

        input[type='checkbox'] {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 26.5px;
          height: 25px;
          border-radius: 6px; // Rounded corners
          border: 1px solid var(--grey-1);
          background-image: linear-gradient(var(--white-color), var(--white-color)),
            linear-gradient(to bottom, var(--blue-color), var(--green-color)); // Gradient border
          background-origin: border-box;
          background-clip: content-box, border-box;
          cursor: pointer;
          transition:
            0.3s ease-in-out,
            border-color 0.3s ease-in-out;

          &:checked {
            background-color: var(--primary-color); // Fill color when checked
            background-image: none; // Removes the gradient when checked
            position: relative;
            border-color: transparent;

            &:before {
              content: '✔';
              color: var(--white-color);
              font-size: 14px;
              display: block;
              text-align: center;
              line-height: 23.5px;
            }
          }
        }
      }
    }
  }
}

@keyframes slideInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
