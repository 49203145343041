.change-configuration-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .change-configuration-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .configuration-multi-select-wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;

      padding: 0 0 20px 0;
      border-bottom: 1px solid var(--grey-2);

      .multi-select-dropdown {
        .multi-select-dropdown-list {
          .dropdown-header {
            height: 48px !important;
            padding: 0 12px;
          }
        }
      }

      .multiple-button {
        margin: 25px 0 0 0;
        display: flex;
        // flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
      }
    }

    .side-heading {
      p {
        font-size: 16px;
        font-weight: 600;
        color: var(--text-color);
      }
    }

    .change-configuration-parameters {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .no-params-required {
        margin: 0;
        text-align: center;
        font-size: 14px;
      }

      .single-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }
    }

    .form-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 20px;

      .button-wrap {
        // padding: 8px 10px;
        width: 70px;
        width: max-content;
      }

      .submit-button {
        width: 150px;
      }
    }
  }
}
